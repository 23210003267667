import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import CustomizedSnackbar from '../../../../../components/common/CustomizedSnackbar'
import React, { useEffect } from 'react'
import moment from 'moment'
import { Text } from '../../../../../styles/global'
import { getMyStealAttempts, postMyStealAttempt } from '../../../../../api/services/steal'
import { getMyTeam } from '../../../../../api/services/team'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import {
  setStealBid,
  setStealTeamDropId,
} from '../../../../../store/slices/stealSlice'
import { stealSchema } from '../../../../../validations/myStealAttempt'
import RetryDialog from './RetryDialog'

export const StealAttemptForm = ({
  collapsedTeam,
  isStealable,
  myTeamPageRefetch
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(stealSchema),
  })

  const teamId = watch('team_id')
  const bid = watch('bid')

  // Redux hooks
  const dispatch = useDispatch()
  const { manager, steal } = useSelector((state) => state)

  // Get received and sent transactions
  const {
    data: transactionsData,
    refetch: refetchTransactions
  } = useQuery(
    ['my_steals', manager.id],
    () => getMyStealAttempts(manager.id),
    {
      enabled: manager.id > 0,
      retry: false,
    }
  )

  // Component hooks
  const [cashLeft, setCashLeft] = useState(0)
  const [retryType, setRetryType] = useState(null)
  const [hasPendingAttemptError, setHasPendingAttemptError] = useState(false)

  // Get my teams
  const { data: myTeams } = useQuery(['my-team'], () => getMyTeam(manager.id))

  //  Post steal attempt
  const { mutate: submitBid, isError, isSuccess, error } = useMutation(() =>
    postMyStealAttempt({
      ...steal,
      team_to_drop_id: teamId == '0' ? null : teamId,
      bid: bid
    }).then(() => {
      refetchTransactions()
      myTeamPageRefetch()
    })
  )

  const handleBidSubmission = () => {
    if (transactionsData?.sent && transactionsData.sent.length > 0) {
      // Set the error state if there's a pending steal attempt
      setHasPendingAttemptError(true)
      return
    }
    submitBid()
  }

  // Cash left update
  useEffect(() => {
    if (manager) {
      setCashLeft(manager.cash_remaining)
    }
  }, [manager])

  // Retry on error
  useEffect(() => {
    if (isError) {
      let errorMessage = error.response.data.detail

      if (errorMessage.includes('started')) {
        if (errorMessage.includes('drop')) {
          setRetryType('DELAYED_DROP')
        } else if (errorMessage.includes('steal')) {
          setRetryType('DELAYED_STEAL')
        } else {
          setRetryType(null)
        }
      } else if (errorMessage.includes('Duplicated')) {
        setRetryType('DUPLICATED')
      } else {
        setRetryType(null)
      }
    }
  }, [isError])

  const resetRetry = () => {
    setRetryType(null)
  }

  //  If choose another team or change bid value, update cash left.
  useEffect(() => {
    const subscription = watch((data) => {
      cashLeftHandler(data)
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [watch('bid')])

  // Calculate the cash left with the team to drop value and bid one as well
  const cashLeftHandler = (data) => {
    const { team_id, bid } = data
    const teamMarketValue = teamValueById(team_id)
    const newBid = bid !== '' ? parseInt(bid) : 0

    setCashLeft(manager.cash_remaining + teamMarketValue - newBid)

    //  team to drop (id, owner_id) and bid on redux
    dispatch(setStealTeamDropId(team_id))
    dispatch(setStealBid(newBid))
  }

  const teamValueById = (teamId) => {
    if (teamId === '' || !teamId) {
      return 0
    }
    const filteredTeam = myTeams?.find((team) => team.team.id === teamId)
    const marketValue = filteredTeam?.team.market_value || 0
    return marketValue
  }

  //Get user current team
  const myTeamsOptions = () => {
    const isOffense = collapsedTeam.team.is_offense
    const filteredMyTeams = myTeams?.filter(
      (teamData) => teamData.team.is_offense === isOffense
    )
    return filteredMyTeams
  }

  // If the team plays before tomorrow at 8 AM UTC, the transaction will be on Wednesday at 8 AM UTC.
  // Otherwise, if the team is claimed, the transaction will happen tomorrow at 8 AM UTC.
  // If the team is unclaimed, the transaction happens immediately.
  // the droped team follows the same logic as well.
  const transactTimeHandler = () => {
    if (!isStealable) {
      return null
    }

    const selectedTeam = myTeams?.find((team) => team.team.id === teamId)
    const collapsedMatchupTime = moment.utc(collapsedTeam.matchup_date)
    const selectedMatchupTime = selectedTeam ? moment.utc(selectedTeam.matchup_date) : null
    const now = moment.utc()

    let tomorrowUtc8 = now.clone().add(1, 'day').hour(8).minute(0)

    if (tomorrowUtc8.diff(now, 'hours') < 24) {
      tomorrowUtc8.add(1, 'day')
    }

    let nextWednesdayUtc8 = now.clone().isoWeekday(3).hour(8).minute(0)
    if (nextWednesdayUtc8.isBefore(now)) {
      nextWednesdayUtc8.add(1, 'week')
    }

    if (collapsedTeam.team.owner_name === null && collapsedMatchupTime.isAfter(now)) {
      return 'immediate'
    } else if (selectedMatchupTime && selectedMatchupTime.isBefore(tomorrowUtc8)) {
      return nextWednesdayUtc8.local().format('MMM DD, h:mm A')
    } else if (collapsedMatchupTime.isBefore(tomorrowUtc8)) {
      return nextWednesdayUtc8.local().format('MMM DD, h:mm A')
    } else if (collapsedTeam.team.owner_name) {
      return tomorrowUtc8.local().format('MMM DD, h:mm A')
    } else {
      return 'immediate'
    }
  }


  return (
    <>
      {retryType === null && (
        <CustomizedSnackbar
          isSuccess={isSuccess}
          duration={3000}
          successMessage="Steal Attempt created"
          isError={isError}
          errorMessage={error?.response.data.detail}
        />
      )}
      <CustomizedSnackbar
        isError={hasPendingAttemptError}
        duration={5500}
        errorMessage="You may only have one steal attempt pending at any given time. Go to the Transactions tab to cancel your existing steal attempt prior to submitting a new one."
      />
      <RetryDialog
        retryType={retryType}
        resetRetry={resetRetry}
        collapsedTeamHasOwner={collapsedTeam.team.owner_name !== null}
        mutate={submitBid}
      />

      <Box marginTop="10px" component="form" onSubmit={handleSubmit(handleBidSubmission)}>
        <Box display="flex">
          <Box width="100%" marginRight="10px">
            <FormControl error={!!errors.team_id} fullWidth>
              <InputLabel>Team to Drop</InputLabel>

              <Select
                id={`teamToDropSelect-${collapsedTeam.team.id}`}
                defaultValue={myTeamsOptions()?.length < 2 ? 0 : ''}
                label="Team to Drop"
                {...register('team_id')}
                onClick={(event) => event.stopPropagation()}
              >
                <MenuItem disabled>
                  {collapsedTeam.team.is_offense ? ' Offense ' : ' Defense '} to drop
                </MenuItem>
                {myTeamsOptions()?.length < 2 && (
                  <MenuItem value={0}>None</MenuItem>
                )}
                {myTeamsOptions()?.map((team) => (
                  <MenuItem value={team.team.id} key={team.team.id}>
                    {`${team.team.name} - $${team.team.market_value}`}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText> {errors.team_id?.message}</FormHelperText>
            </FormControl>
          </Box>

          <TextField
            id={`bidBtn-${collapsedTeam.team.id}`}
            {...register('bid')}
            inputProps={{ inputMode: 'numeric' }}
            type="number"
            label="Your Bid $"
            error={!!errors.bid}
            helperText={errors.bid?.message}
            defaultValue={collapsedTeam.team.market_value + 1}
            onClick={(event) => event.stopPropagation()}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" margin="10px 0">
          <Text variant="body2">Transact Time: {transactTimeHandler()}</Text>
          <Text variant="body2">Cash Left: ${cashLeft}</Text>
        </Box>
        <Button
          id='submitBidBtn'
          variant="contained"
          type="submit"
          disabled={isSuccess || hasPendingAttemptError}
          fullWidth
          onClick={(event) => event.stopPropagation()}
        >
          Submit Bid
        </Button>
      </Box>
    </>
  )
}
