import { React, Box, Typography, Grid, Chip, Tooltip } from '@mui/material'
import { DateFormatDay, ordinalSuffixHandler, formatNumberToOneDecimal } from '../../helpers/utils'
import WarningIcon from '@mui/icons-material/Warning'
import LockIcon from '@mui/icons-material/Lock'
import moment from 'moment'

const TeamInfo = ({ transaction, next_matchup, isStealable }) => {

  const showDashForTeamRanks = next_matchup.team.points_per_game === 0 && next_matchup.team.points_per_game_allowed === 0
  const showDashForOpponentRanks = next_matchup.opponent?.ppg === 0 && next_matchup.opponent?.ppga === 0


  const isMatchupBeforeTomorrow = () => {
    const collapsedMatchupTime = moment.utc(next_matchup.matchup_date)
    const now = moment.utc()
    const tomorrowUtc8 = now.clone().add(1, 'day').hour(8).minute(0)

    if (tomorrowUtc8.diff(now, 'hours') < 24) {
      tomorrowUtc8.add(1, 'day')
    }

    return collapsedMatchupTime.isBetween(now, tomorrowUtc8)
  }

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%" marginTop={0.5}
      paddingBottom={isStealable ? 0.5 : 2}
    >
      <Box
        marginY={transaction.result == 'filibuster' ? 1 : 0} paddingLeft={1}
        display="flex" flexDirection="column" alignItems="start" justifyContent="start" minWidth="175px">
        {/* Owned team */}
        <Typography whiteSpace='nowrap' marginTop={0} component="span" variant='h6' fontSize="0.97rem">
          <span style={{ fontWeight: 'bold' }}>
            {transaction.kind === 'offense' ? 'Off' : 'Def'}{transaction.team_to_steal_name}
          </span>
          <span style={{ color: 'green' }}>
            {' '}${transaction.team_to_steal_value}
          </span>
          {isStealable && (
            moment.utc().isAfter(moment.utc(next_matchup.matchup_date)) ? (
              <Tooltip title="This team's game has already started." arrow>
                <LockIcon htmlColor="red" sx={{ marginLeft: 1, paddingBottom: '3px' }} />
              </Tooltip>
            ) : (
              isMatchupBeforeTomorrow() && (
                <Tooltip title="Transaction will process after the team is scheduled to play" arrow>
                  <WarningIcon htmlColor="gold" sx={{ marginLeft: 1, paddingBottom: '3px' }} />
                </Tooltip>
              )
            )
          )}
        </Typography>
        {/* Next matchup */}
        <Typography marginTop={0} variant='h7' fontSize="0.90rem">
          {next_matchup.opponent ? (
            <>
              {transaction.kind !== 'offense' ? 'Off' : 'Def'}-{next_matchup.opponent.abbreviation} | {DateFormatDay(next_matchup.matchup_date)}
            </>
          ) : 'Bye week'}

        </Typography>
        {
          isStealable &&
          <Typography marginTop={0} variant='h7' fontSize="0.85rem">
            {next_matchup.team.owner_name ? `Owner: ${next_matchup.team.owner_name}` : 'Unowned'}
          </Typography>
        }
        {transaction.result == 'filibuster' &&
          <Chip sx={{
            borderRadius: '5px', height: '20px', fontWeight: 'bold',
            color: 'white'
          }}
            color='primary'
            label="Filibustered" />
        }
      </Box>

      <Box
        width={'50%'}
        display="flex" alignItems="center" flexDirection="column" justifyContent="space-between">
        <Grid container spacing={0}>
          <Grid item xs={6} paddingBottom={0.5}>
            {/* Owned team score */}
            <Box textAlign="center">
              {transaction.kind === 'offense' ?
                <>
                  <Typography fontSize={15}>
                    {formatNumberToOneDecimal(next_matchup.team.points_per_game)}{' '}
                    {showDashForTeamRanks ? '-' : `(${ordinalSuffixHandler(next_matchup.team.ppg_rank)})`}
                  </Typography>
                  <Typography fontSize={10} fontWeight='bold' color='gray' noWrap title="Points per game scored">
                    Pts. Per Game
                  </Typography>
                </> :
                <>
                  <Typography fontSize={15}>
                    {formatNumberToOneDecimal(next_matchup.team.points_per_game_allowed)}{' '}
                    {showDashForTeamRanks ? '-' : `(${ordinalSuffixHandler(next_matchup.team.ppga_rank)})`}
                  </Typography>
                  <Typography fontSize={10} fontWeight='bold' color='gray' noWrap title="Points per game allowed">
                    PPG Allowed
                  </Typography>
                </>
              }
            </Box>
          </Grid>
          <Grid item xs={6} paddingBottom={0.5}>
            {/* Opponent team score */}
            {next_matchup.opponent && <Box
              textAlign="center">
              {transaction.kind === 'offense' ?
                <>
                  <Typography fontSize={15}>
                    {formatNumberToOneDecimal(next_matchup.opponent.ppga)}{' '}
                    {showDashForOpponentRanks ? '-' : `(${ordinalSuffixHandler(next_matchup.opponent.ppga_rank)})`}
                  </Typography>
                  <Typography fontSize={10} fontWeight='bold' color='gray' noWrap title="Opponent points per game allowed">
                    Opp. PPG Allowed
                  </Typography>
                </> :
                <>
                  <Typography fontSize={15}>
                    {formatNumberToOneDecimal(next_matchup.opponent.ppg)}{' '}
                    {showDashForOpponentRanks ? '-' : `(${ordinalSuffixHandler(next_matchup.opponent.ppg_rank)})`}
                  </Typography>
                  <Typography
                    fontSize={10} fontWeight='bold' color='gray' noWrap title="Opponent points per game scored">
                    Opp. PPG
                  </Typography>
                </>
              }
            </Box>}
          </Grid>
          <Grid item xs={6}>
            {/* Owned team projections */}
            <Box textAlign="center">
              <Typography fontSize={15}>
                {formatNumberToOneDecimal(next_matchup.team.projected_points)}{' '}
                ({ordinalSuffixHandler(next_matchup.team.projected_points_rank)})
              </Typography>
              <Typography whiteSpace="nowrap" fontSize={10} fontWeight='bold' color='gray' title="Projected points">
                Proj. Points
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box textAlign="center">
              <Typography fontSize={15}>
                {next_matchup.team.proj_points_efficiency
                  ? `${formatNumberToOneDecimal(next_matchup.team.proj_points_efficiency)}x`
                  : ' - '}
              </Typography>
              <Typography whiteSpace="nowrap" fontSize={10} fontWeight='bold' color='gray' title="Projected points over market value">
                Cap Rate
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box >
  )
}

export default TeamInfo
