import styled from '@emotion/styled'
import { Box } from '@mui/system'

export const AdminTitle = styled(Box)`
  margin: 20px 0;
  display: flex;
  justify-content: center;
`

export const AdminSubTitle = styled(Box)`
  margin: 10px 0;
  display: flex;
  justify-content: left;
`

export const AdminSelectRow = styled(Box)`
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
`

export const AdminRow = styled(Box)`
  width: 100%;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
`

export const AdminButtonsRow = styled(Box)`
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
`
