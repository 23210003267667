import { Button, LinearProgress, TextField, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../../components/Navbar'
import BasicSelect from './components/BasicSelect'
import DatePickerInput from './components/DatePickerInput'

import { AdminButtonsRow, AdminRow, AdminSelectRow, AdminSubTitle, AdminTitle } from './styles'
import {
  fillLeague,
  moveToMatchUp,
  scrollToWeek,
  simulatorStatus,
  startFakeLeague,
  startUpdater,
  stopUpdater,
} from '../../api/services/simulator'
import {
  startNFLUpdater,
  stopNFLUpdater,
  ingestNFLData,
} from '../../api/services/nfl'
import { useMutation, useQuery } from '@tanstack/react-query'
import CustomizedSnackbar from '../../components/common/CustomizedSnackbar'
import { allowDashboardAccess } from '../../helpers/utils'
import { useNavigate } from 'react-router-dom'
import { updateUserSeasonCurrentWeek, updateSeason } from '../../store/slices/userSlice'

export const Admin = () => {
  const { leagues, email, season } = useSelector((state) => state.user)

  const [startDate, setStartDate] = useState(new Date())
  const [scrollWeek, setScrollWeek] = useState()
  const [leagueToFillId, setLeagueToFillId] = useState()
  const [startMatchupId, setStartMatchupId] = useState()

  const navigate = useNavigate()
  const dispatch = useDispatch()

   // Start NFL Periodic Updater
   const { mutate: startNFL } = useMutation(['start_nfl_updater'], startNFLUpdater, {
    onSuccess: (data) => {
      dispatch(updateSeason(data))
      console.log('NFL Updater started successfully.')
    }
  })

  // Stop NFL Periodic Updater
  const { mutate: stopNFL } = useMutation(['stop_nfl_updater'], stopNFLUpdater, {
    onSuccess: (data) => {
      dispatch(updateSeason(data))
      console.log('NFL Updater stopped successfully.')
    }
  })

  const {
    mutate: ingestNFL,
    isLoading: ingestNFLLoading,
  } = useMutation(['ingest_nfl_data'], ingestNFLData, {
    onSuccess: (data) => {
      dispatch(updateSeason(data))
    },
    onError: (error) => {
      console.error('Error ingesting NFL data:', error)
    }
  })

  // Get the current simulation status
  const {
    data: simulator,
    refetch: refetchSimulator,
    loading: simulatorLoading,
  } = useQuery(['simulator_status'], () => simulatorStatus(), {
    retry: false,
    onSuccess: (data) => setScrollWeek(data.season.current_week),
  })

  // Start fake league
  const {
    mutate: startFakeLeagueMutate,
    isLoading: startFakeLeagueLoading,
    isError: startFakeLeagueLoadingIsError,
    error: startFakeLeagueLoadingError,
    isSuccess: startFakeLeagueLoadingIsSuccess,
  } = useMutation(['start_fake_league'], () => startFakeLeague(startDate))

  // Scroll to week
  const {
    mutate: scrollToWeekMutate,
    isLoading: scrollToWeekLoading,
    isError: scrollToWeekLoadingIsError,
    error: scrollToWeekLoadingError,
    isSuccess: scrollToWeekLoadingIsSuccess,
  } = useMutation(['scroll_to_week'], () => scrollToWeek(scrollWeek), {
    onSuccess: () => dispatch(updateUserSeasonCurrentWeek(scrollWeek)),
  })

  // League to be filled
  const {
    mutate: fillLeagueMutate,
    isLoading: fillLeagueLoading,
    isError: fillLeagueIsError,
    error: fillLeagueError,
    isSuccess: fillLeagueIsSuccess,
  } = useMutation(['fill-league'], () => fillLeague(leagueToFillId))

  // Move to matchup
  const {
    mutate: moveToMatchupMutate,
    isLoading: moveToMatchupLoading,
    isError: moveToMatchupIsError,
    error: moveToMatchupError,
    isSuccess: moveToMatchupIsSuccess,
  } = useMutation(['move_to_matchup'], () => moveToMatchUp(startMatchupId), {
    onSuccess: () => refetchSimulator(),
  })

  // Start updater
  const { mutate: startSimulator } = useMutation(
    ['start_updater'],
    () => startUpdater(),
    {
      onSuccess: () => refetchSimulator(),
    }
  )

  // Stop updater
  const { mutate: stopSimulator } = useMutation(['stop_updater'], () => stopUpdater(), {
    onSuccess: () => refetchSimulator(),
  })

  if (simulatorLoading) {
    return null
  }

  const startMatchupHandle = (matchupId) => {
    setStartMatchupId(matchupId)
  }

  const startLeagueToFillHandle = (leagueToFillId) => {
    setLeagueToFillId(leagueToFillId)
  }

  // If a random user type /dashboard, redirect to 404 page.
  if (!allowDashboardAccess.includes(email)) {
    navigate('/404')
  }

  return (
    <>
      <Navbar />
      {/* Start fake league alerts */}
      <CustomizedSnackbar
        isError={startFakeLeagueLoadingIsError}
        isSuccess={startFakeLeagueLoadingIsSuccess}
        errorMessage={startFakeLeagueLoadingError?.response.data.message}
      />
      {/* Scroll to week alerts */}
      <CustomizedSnackbar
        isError={scrollToWeekLoadingIsError}
        isSuccess={scrollToWeekLoadingIsSuccess}
        errorMessage={scrollToWeekLoadingError?.response.data.message}
      />

      {/* League to be filled alerts */}
      <CustomizedSnackbar
        isError={fillLeagueIsError}
        isSuccess={fillLeagueIsSuccess}
        errorMessage={fillLeagueError?.response.data.message}
      />
      {/* Start matchup now alerts */}
      <CustomizedSnackbar
        isError={moveToMatchupIsError}
        isSuccess={moveToMatchupIsSuccess}
        errorMessage={moveToMatchupError?.response.data.message}
      />
      <Container maxWidth="sm">
        <AdminTitle>
          <Typography variant="h5" color="primary" fontWeight={'bold'}>
            Admin Dashboard
          </Typography>
        </AdminTitle>

        {/* League status */}
        <AdminSubTitle>
          <Typography variant="h6" color="primary" fontWeight={'bold'}>
            League status
          </Typography>
        </AdminSubTitle>
        <AdminRow>
          <Typography> Current season: </Typography>
          <Typography fontWeight={'bold'}> {season?.current_season} </Typography>
        </AdminRow>
        <AdminRow>
          <Typography> Current Week: </Typography>
          <Typography fontWeight={'bold'}> {season?.current_week} </Typography>
        </AdminRow>
        <AdminRow>
          <Typography> Season Stage: </Typography>
          <Typography fontWeight={'bold'}> {season?.draft_time ? 'Draft time' : 'On season'} </Typography>
        </AdminRow>
        <AdminRow>
          <Typography> Updater: </Typography>
          <Typography fontWeight={'bold'}> {season?.live ? 'enabled' : 'disabled'} </Typography>
        </AdminRow>
        <AdminButtonsRow>
          <Button
            sx={{ backgroundColor: 'green' }}
            variant="contained"
            disabled={season?.live || ingestNFLLoading}
            onClick={ingestNFL}
          >
            Ingest NFL Data
          </Button>
        </AdminButtonsRow>
        {ingestNFLLoading && <LinearProgress />}
        <AdminButtonsRow>
          <Button
            sx={{ backgroundColor: 'green' }}
            variant="contained"
            disabled={season?.live || ingestNFLLoading }
            onClick={startNFL}
          >
            Start Updater
          </Button>
          <Button
            sx={{ backgroundColor: 'red' }}
            variant="contained"
            disabled={!season?.live}
            onClick={stopNFL}
          >
            Pause Updater
          </Button>
        </AdminButtonsRow>

        {/* Simulator tools */}
        <AdminSubTitle>
          <Typography variant="h6" color="primary" fontWeight={'bold'}>
            Simulator
          </Typography>
        </AdminSubTitle>
        <AdminRow>
          <Typography> Simulator Mode Status: </Typography>
          <Typography fontWeight={'bold'}> {simulator?.enabled ? 'Enabled' : 'Disabled'} </Typography>
        </AdminRow>
        <AdminRow>
          <Typography> Fake NFL data: </Typography>
          <Typography fontWeight={'bold'}> {simulator?.season ? 'Ready' : 'Not ready'} </Typography>
        </AdminRow>
        <AdminButtonsRow>
          <Button
            sx={{ backgroundColor: 'green' }}
            variant="contained"
            disabled={simulator?.enabled || !simulator?.season || startFakeLeagueLoading}
            onClick={startSimulator}
          >
            Enable
          </Button>
          <Button
            sx={{ backgroundColor: 'red' }}
            variant="contained"
            disabled={!simulator?.enabled}
            onClick={stopSimulator}
          >
            Disable
          </Button>
        </AdminButtonsRow>
        <AdminSelectRow>
          <DatePickerInput
            fakeLeagueStartDate={startDate}
            setFakeLeagueStartDate={setStartDate}
            simulator={simulator}
          />
          <Button
            variant="contained"
            onClick={startFakeLeagueMutate}
            disabled={startFakeLeagueLoading}
          >
            RUN
          </Button>
        </AdminSelectRow>
        {startFakeLeagueLoading && <LinearProgress />}
        <AdminSelectRow>
          <TextField
            label="Scroll to week"
            variant="outlined"
            onChange={(e) => setScrollWeek(e.target.value)}
            defaultValue={simulator?.season?.current_week}
          />
          <Button
            variant="contained"
            onClick={scrollToWeekMutate}
            disabled={scrollToWeekLoading || !simulator?.enabled}
          >
            RUN
          </Button>
        </AdminSelectRow>
        <AdminSelectRow>
          {/* Bring matchup by calling /simulator/status and looking into weekly match ups */}
          <BasicSelect
            label="Start matchup now"
            options={simulator?.weekly_matchups}
            onChange={startMatchupHandle}
            data={startMatchupId}
            isMatchup
            fontSize={'0.8rem'}
          />
          <Button
            variant="contained"
            disabled={!simulator?.enabled || moveToMatchupLoading}
            onClick={moveToMatchupMutate}
          >
            RUN
          </Button>
        </AdminSelectRow>
        {/* Generic tools */}
        <AdminSubTitle>
          <Typography variant="h6" color="primary" fontWeight={'bold'}>
            Tools
          </Typography>
        </AdminSubTitle>
        <AdminSelectRow>
          <BasicSelect
            label="League to be filled"
            options={leagues}
            onChange={startLeagueToFillHandle}
            data={leagueToFillId}
          />
          <Button
            variant="contained"
            disabled={fillLeagueLoading}
            onClick={fillLeagueMutate}
          >
            RUN
          </Button>
        </AdminSelectRow>
      </Container>
    </>
  )
}
