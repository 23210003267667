import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, IconButton, Menu, MenuItem, Badge, Tooltip } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { setLeague } from '../../store/slices/leagueSlice'
import { setManager } from '../../store/slices/managerSlice'
import { setStealData } from '../../store/slices/stealSlice'
import { changeCurrentLeague } from '../../helpers/utils'
import LeagueFormDialog from '../../pages/league/MyLeagues/LeagueFormDialog'
import { getAllLeagues } from '../../api/services/leagues'
import { updateUserLeagues } from '../../store/slices/userSlice'
import { useQuery } from '@tanstack/react-query'
import LeagueSelectorItem from './LeagueSelectorItem'
import { useNavigate } from 'react-router-dom'

export const LeagueSelector = ({ dispatch }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { user } = useSelector((state) => state)
  const season = useSelector((state) => state?.user.season)
  const { current_week, week_started } = season
  const [isMock, setIsMock] = useState(false)
  const [hasAvailableDraft, setHasAvailableDraft] = useState(false)
  const navigate = useNavigate()

  const { refetch: leaguesRefetch } = useQuery(
    ['all-leagues'],
    () => getAllLeagues(),
    {
      onSuccess: (leaguesData) => {
        dispatch(updateUserLeagues(leaguesData))
        setHasAvailableDraft(false)
      },
    }
  )

  const isCreateLeagueDisabled = () => {
    if (current_week > 14) {
      return true
    }

    if (current_week === 13 && week_started) {
      return true
    }

    return false
  }

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const changeLeagueHandler = (selectedLeagueId) => {
    const { selectedLeague, selectedManager } = changeCurrentLeague(
      user,
      selectedLeagueId
    )
    if (selectedLeague.is_mock) {
      navigate('/league')
    }
    dispatch(setLeague(selectedLeague))
    dispatch(setManager(selectedManager))
    dispatch(setStealData(selectedManager))
    handleCloseMenu()
  }

  return (
    <>
      <IconButton id='leagueSelectorBtn' onClick={handleOpenMenu}>
        <Badge
          color="secondary"
          variant="dot"
          invisible={!hasAvailableDraft}
          sx={{
            '& .MuiBadge-dot': {
              height: 12,
              minWidth: 12,
              borderRadius: '50%',
              marginRight: '-5px',
              marginTop: '3px'
            },
          }}
        >
          <MenuIcon sx={{ color: 'white' }} />
        </Badge>
      </IconButton>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {user?.leagues
          .filter(l => !l.is_mock)
          .map((league) => {
            const manager = user.managers.find(m => m.league_id === league.id)
            return (
              <LeagueSelectorItem
                key={league.id}
                league={league}
                manager={manager}
                changeLeagueHandler={changeLeagueHandler}
                setHasAvailableDraft={setHasAvailableDraft}
              />
            )
          })}

        {user?.leagues
          .filter(l => l.is_mock)
          .map((league) => {
            const manager = user.managers.find(m => m.league_id === league.id)
            return (
              <LeagueSelectorItem
                key={league.id}
                league={league}
                manager={manager}
                changeLeagueHandler={changeLeagueHandler}
                isMock={true}
                setHasAvailableDraft={setHasAvailableDraft}
              />
            )
          })}

        <Divider />
        <Tooltip
          title={
            isCreateLeagueDisabled()
              ? 'You cannot start a league after the start of week 14 matchups.'
              : ''
          }
          placement="right"
        >
          <div>
            <MenuItem disabled={isCreateLeagueDisabled()}>
              <LeagueFormDialog
                isMock={isMock}
                setIsMock={setIsMock}
                leaguesRefetch={leaguesRefetch}
                closeMenu={handleCloseMenu}
              />
            </MenuItem>
          </div>
        </Tooltip>
      </Menu>
    </>
  )
}
