import * as React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Slide,
} from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import LockIcon from '@mui/icons-material/Lock'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  deleteLeague,
  getAllLeagues,
  lockLeague,
} from '../../../../../api/services/leagues'
import CustomizedSnackbar from '../../../../../components/common/CustomizedSnackbar'
import {
  resetLeague,
  setLeague,
  setLockLeague,
} from '../../../../../store/slices/leagueSlice'
import DeleteIcon from '@mui/icons-material/Delete'
import AlertDialog from '../../../../../components/common/AlertDialog'
import {
  setLockSingleUserLeague,
  updateUserLeagues,
} from '../../../../../store/slices/userSlice'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { messages } from '../../../../../messages'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ManageLeagueDialog({ leagueData, refetchLeague }) {
  const dispatch = useDispatch()
  const { league: stateLeague, user } = useSelector((state) => state)
  const [open, setOpen] = React.useState(false)
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const navigate = useNavigate()

  const season = useSelector((state) => state?.user.season)
  const { current_week, week_started } = season

  const [isCopiedToClipboard, setIsCopiedToClipboard] = React.useState(false)

  const handleDisabledLock = () => {
    if (current_week > 14) {
      return true
    }

    if (current_week === 14 && week_started) {
      return true
    }


    return leagueData.is_locked
  }

  const getLockButtonText = () => {

    if (leagueData.is_locked) {
      return 'Locked'
    }

    if (current_week > 14 || (current_week === 14 && week_started)) {
      return 'Cannot Lock'
    }

    return 'Lock'
  }

  const { mutate, isLoading, error, isError, isSuccess } = useMutation(
    ['lock-league'],
    () => lockLeague(leagueData.id),
    {
      onSuccess: () => {
        afterLockHandler()
      },
      enabled: false,
      retry: 0,
    }
  )

  const {
    mutate: mutateDelete,
    isLoading: isLoadingDelete,
    error: errorDelete,
    isError: isErrorDelete,
    isSuccess: isSuccessDelete,
  } = useMutation(['delete-league'], () => deleteLeague(leagueData.id), {
    onSuccess: () => {
      afterDeleteHandler(leagueData.id)
    },
  })

  const { refetch: refetchAllLeagues } = useQuery(
    ['all-leagues'],
    () => getAllLeagues(),
    {
      retry: false,
      enabled: false,
      onSuccess: (updatedLeaguesData) => {
        dispatch(updateUserLeagues(updatedLeaguesData))
      },
    }
  )

  const afterLockHandler = () => {
    // If locked league is the current select one
    if (!leagueData.is_mock && leagueData.id === stateLeague.id) {
      dispatch(setLockLeague())
      dispatch(setLockSingleUserLeague(leagueData.id))
    } else {
      refetchAllLeagues()
      setOpen(false)
      // Recall the get leagues endpoint and update the redux with updateUserLeagues (user slice)
    }
    refetchLeague()
    if (!leagueData.is_mock) {
      setTimeout(() => navigate('/matchups'), 1000)
    }
  }

  const afterDeleteHandler = (deletedLeagueId) => {
    // Delete specific league and update user's league
    const remainingLeagues = user.leagues.filter(
      (league) => league.id !== deletedLeagueId
    )

    // 1 second time out, to display the snackbar success message.
    setTimeout(() => {
      dispatch(updateUserLeagues(remainingLeagues))

      // Change current league with an available one, if exists
      if (remainingLeagues[0]) {
        dispatch(setLeague(remainingLeagues[0]))
      } else {
        dispatch(resetLeague())
      }

      //  Close dialogs
      setOpenConfirm(false)
      handleClose()
    }, 2000)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const invite_link = `${process.env.REACT_APP_CALLBACK_URL}?invite_code=${leagueData.password}`

  const copyPasswordHandler = () => {
    navigator.clipboard.writeText(invite_link)
    setIsCopiedToClipboard(true)
    setTimeout(() => {
      setIsCopiedToClipboard(false)
    }, 2000)
  }

  return (
    <div>
      <Button
        sx={{ marginLeft: 1 }}
        id='manageLeagueBtn' variant="outlined" onClick={handleClickOpen}>
        {messages[14].manage}
      </Button>
      <AlertDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        handleDelete={mutateDelete}
        isLoading={isLoadingDelete}
      />
      <CustomizedSnackbar
        isError={isError}
        isSuccess={isSuccess}
        errorMessage={
          error?.response.status === 403 ? error.response.data.message : null
        }
        successMessage={'League successfully locked'}
      />
      <CustomizedSnackbar
        isError={isErrorDelete}
        isSuccess={isSuccessDelete}
        errorMessage={
          errorDelete?.response.status === 403
            ? errorDelete.response.data.message
            : null
        }
        successMessage={'League successfully deleted'}
      />
      <Dialog
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Manage {leagueData.name}</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            Invite Link: {invite_link}
          </DialogContentText>
          <div
            onClick={copyPasswordHandler}
            className='copyLeagueCodeBtn'
            id={`copyLeagueCodeBtn_${leagueData.id}`}
            style={{
              cursor: 'pointer'
            }}
          >
            {isCopiedToClipboard ? (
              <CheckCircleIcon color="primary" />
            ) : (
              <ContentCopyIcon color="primary" />
            )}
          </div>
        </DialogContent>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <DialogActions>
            <div
              className="deleteLeagueBtn">
              <Button
                id={`deleteLeagueBtn_${leagueData.id}`}
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={() => setOpenConfirm(true)}
                sx={{ display: 'flex', alignItems: 'center', paddingX: 1, paddingY: 0.5 }}
              >
                <span style={{
                  marginTop: 4
                }}
                >
                  Delete
                </span>
              </Button>
            </div>
            <div
              className="lockLeagueBtn">
              <Button
                id={`lockLeagueBtn_${leagueData.id}`}
                variant="outlined"
                startIcon={<LockIcon />}
                disabled={handleDisabledLock()}
                onClick={() => mutate()}
                sx={{ display: 'flex', alignItems: 'center', paddingX: 1, paddingY: 0.5 }}
              >
                <span style={{
                  marginTop: 3
                }}
                >
                  {getLockButtonText()}
                </span>
              </Button>
            </div>
          </DialogActions>
        )}
        {handleDisabledLock() && !leagueData.is_locked && (
          <div className="alert alert-warning">
            You cannot lock the league after the start of week 14 matchups.
          </div>
        )}
      </Dialog>
    </div >
  )
}
