import { Box, Collapse, IconButton } from '@mui/material'
import React from 'react'
import { TeamCardSchedule } from '../TeamCardSchedule'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useDispatch } from 'react-redux'
import { setStealTransfer } from '../../../../store/slices/stealSlice'
import TeamInfo from '../../../../components/TeamInfo'

export default function TeamRow({ team, myTeamPageRefetch, isStealable = false, id }) {
  const [showSchedule, setShowSchedule] = React.useState(false)
  const dispatch = useDispatch()

  const handleToggleButtons = () => {
    setShowSchedule(prev => !prev)
    if (!showSchedule) {
      const data = {
        teamToTransferId: team.team.id,
        ownerId: parseInt(team.team.owner_id),
      }
      dispatch(setStealTransfer(data))
    }
  }

  return (
    <Box
      key={team.team.id}
      id={id}
      display="flex"
      flexDirection="column"
      alignItems="center"
      borderBottom="1px solid lightgray"
      padd
      onClick={handleToggleButtons} // Toggle for the entire row
    >
      <TeamInfo
        isStealable={isStealable}
        transaction={{
          team_to_steal_name: team.team.name,
          team_to_steal_value: team.team.market_value,
          kind: team.team.is_offense ? 'offense' : 'defense'
        }} next_matchup={team}
      />
      {
        isStealable &&
        <>
          <Collapse in={showSchedule} sx={{ maxWidth: '100%' }}>
            <TeamCardSchedule
              myTeamPageRefetch={myTeamPageRefetch}
              collapsedTeamId={showSchedule ? team.id : 0}
              teamId={team.id}
              collapsedTeam={team}
              isStealable={isStealable} />
          </Collapse><IconButton sx={{ margin: 0, padding: 0.35 }}>
            {showSchedule ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </>
      }
    </Box>
  )
}
